import actionTypes from "../actions/actionTypes";
import { REQUEST, FAILURE, SUCCESS } from "./action-type.util";

const INTIAL_STATE = {};

const virtualAccountReducer = (state = INTIAL_STATE, actions) => {
  switch (actions.type) {
    case REQUEST(actionTypes.POST_CHARGE_BACK):
      return {
        ...state,
        postChargeBackLoading: true,
        postChargeBackPayload: null,
        postChargeBackError: null,
      };
    case SUCCESS(actionTypes.POST_CHARGE_BACK):
      return {
        ...state,
        postChargeBackPayload: actions.payload,
        postChargeBackError: null,
        postChargeBackLoading: false,
      };
    case FAILURE(actionTypes.POST_CHARGE_BACK):
      return {
        ...state,
        postChargeBackError: actions.payload,
        postChargeBackPayload: null,
        postChargeBackLoading: false,
      };
    case REQUEST(actionTypes.GET_CHARGE_BACK):
      return {
        ...state,
        getChargeBackLoading: true,
        getChargeBackPayload: null,
        getChargeBackError: null,
      };
    case SUCCESS(actionTypes.GET_CHARGE_BACK):
      return {
        ...state,
        getChargeBackPayload: actions.payload,
        getChargeBackError: null,
        getChargeBackLoading: false,
      };
    case FAILURE(actionTypes.GET_CHARGE_BACK):
      return {
        ...state,
        getChargeBackError: actions.payload,
        getChargeBackPayload: null,
        getChargeBackLoading: false,
      };
    case REQUEST(actionTypes.GET_SETTLEMENT):
      return {
        ...state,
        getSettlementLoading: true,
        getSettlementPayload: null,
        getSettlementError: null,
      };
    case SUCCESS(actionTypes.GET_SETTLEMENT):
      return {
        ...state,
        getSettlementPayload: actions.payload,
        getSettlementError: null,
        getSettlementLoading: false,
      };
    case FAILURE(actionTypes.GET_SETTLEMENT):
      return {
        ...state,
        getSettlementError: actions.payload,
        getSettlementPayload: null,
        getSettlementLoading: false,
      };
    case REQUEST(actionTypes.GET_ALL_SETTLEMENTS):
      return {
        ...state,
        fetchingSettlements: true,
        allSettlements: null,
        allSettlementsError: null,
      };
    case SUCCESS(actionTypes.GET_ALL_SETTLEMENTS):
      return {
        ...state,
        allSettlements: actions.payload,
        allSettlementsError: null,
        fetchingSettlements: false,
      };
    case FAILURE(actionTypes.GET_ALL_SETTLEMENTS):
      return {
        ...state,
        allSettlementsError: actions.payload,
        allSettlements: null,
        fetchingSettlements: false,
      };
    case REQUEST(actionTypes.GET_UNSETTLED_TRANSACTIONS):
      return {
        ...state,
        fetchingUnsettledTransactions: true,
        unsettledTransactions: null,
        unsettledTransactionsError: null,
      };
    case SUCCESS(actionTypes.GET_UNSETTLED_TRANSACTIONS):
      return {
        ...state,
        unsettledTransactions: actions.payload,
        unsettledTransactionsError: null,
        fetchingUnsettledTransactions: false,
      };
    case FAILURE(actionTypes.GET_UNSETTLED_TRANSACTIONS):
      return {
        ...state,
        unsettledTransactionsError: actions.payload,
        unsettledTransactions: null,
        fetchingUnsettledTransactions: false,
      };
    case REQUEST(actionTypes.GET_MERCHANT_TRANSACTIONS):
      return {
        ...state,
        fetchingMerchantTransactions: true,
        merchantTransactions: null,
        merchantTransactionsError: null,
      };
    case SUCCESS(actionTypes.GET_MERCHANT_TRANSACTIONS):
      return {
        ...state,
        merchantTransactions: actions.payload,
        merchantTransactionsError: null,
        fetchingMerchantTransactions: false,
      };
    case FAILURE(actionTypes.GET_MERCHANT_TRANSACTIONS):
      return {
        ...state,
        merchantTransactionsError: actions.payload,
        merchantTransactions: null,
        fetchingMerchantTransactions: false,
      };
    case REQUEST(actionTypes.GET_BATCH_TRANSACTIONS):
      return {
        ...state,
        fetchingBatchTransactions: true,
        batchTransactions: null,
        batchTransactionsError: null,
      };
    case SUCCESS(actionTypes.GET_BATCH_TRANSACTIONS):
      return {
        ...state,
        batchTransactions: actions.payload,
        batchTransactionsError: null,
        fetchingBatchTransactions: false,
      };
    case FAILURE(actionTypes.GET_BATCH_TRANSACTIONS):
      return {
        ...state,
        batchTransactionsError: actions.payload,
        batchTransactions: null,
        fetchingBatchTransactions: false,
      };

    case REQUEST(actionTypes.POST_COMPLETE_SETTLEMENT):
      return {
        ...state,
        completingSettlement: true,
        completeSettlementPayload: null,
        completeSettlementError: null,
      };
    case SUCCESS(actionTypes.POST_COMPLETE_SETTLEMENT):
      return {
        ...state,
        completeSettlementPayload: actions.payload,
        completeSettlementError: null,
        completingSettlement: false,
      };
    case FAILURE(actionTypes.POST_COMPLETE_SETTLEMENT):
      return {
        ...state,
        completeSettlementError: actions.payload,
        completeSettlementPayload: null,
        completingSettlement: false,
      };

    case REQUEST(actionTypes.TRANSACTION):
      return {
        ...state,
        getTransactionLoading: true,
        getTransactionPayload: null,
        getTransactionError: null,
      };
    case SUCCESS(actionTypes.TRANSACTION):
      return {
        ...state,
        getTransactionPayload: actions.payload,
        getTransactionError: null,
        getTransactionLoading: false,
      };
    case FAILURE(actionTypes.TRANSACTION):
      return {
        ...state,
        getTransactionError: actions.payload,
        getTransactionPayload: null,
        getTransactionLoading: false,
      };
    case REQUEST(actionTypes.GET_PARTNER):
      return {
        ...state,
        gettingPartnerLoading: true,
        gettingPartnerPayload: null,
        gettingPartnerError: null,
      };
    case SUCCESS(actionTypes.GET_PARTNER):
      return {
        ...state,
        gettingPartnerPayload: actions.payload,
        gettingPartnerError: null,
        gettingPartnerLoading: false,
      };
    case FAILURE(actionTypes.GET_PARTNER):
      return {
        ...state,
        gettingPartnerError: actions.payload,
        gettingPartnerPayload: null,
        gettingPartnerLoading: false,
      };
    case REQUEST(actionTypes.TOGGLE_PARTNER):
      return {
        ...state,
        togglePartnerLoading: true,
        togglePartnerPayload: null,
        togglePartnerError: null,
      };
    case SUCCESS(actionTypes.TOGGLE_PARTNER):
      return {
        ...state,
        togglePartnerPayload: actions.payload,
        togglePartnerError: null,
        togglePartnerLoading: false,
      };
    case FAILURE(actionTypes.TOGGLE_PARTNER):
      return {
        ...state,
        togglePartnerError: actions.payload,
        togglePartnerPayload: null,
        togglePartnerLoading: false,
      };

    case REQUEST(actionTypes.GET_REQUEST):
      return {
        ...state,
        getRequestLoading: true,
        getRequestPayload: null,
        getRequestError: null,
      };
    case SUCCESS(actionTypes.GET_REQUEST):
      return {
        ...state,
        getRequestPayload: actions.payload,
        getRequestError: null,
        getRequestLoading: false,
      };
    case FAILURE(actionTypes.GET_REQUEST):
      return {
        ...state,
        getRequestError: actions.payload,
        getRequestPayload: null,
        getRequestLoading: false,
      };
    case REQUEST(actionTypes.TOGGLE_REQUEST):
      return {
        ...state,
        toggleRequestLoading: true,
        toggleRequestPayload: null,
        toggleRequestError: null,
      };
    case SUCCESS(actionTypes.TOGGLE_REQUEST):
      return {
        ...state,
        toggleRequestPayload: actions.payload,
        toggleRequestError: null,
        toggleRequestLoading: false,
      };
    case FAILURE(actionTypes.TOGGLE_REQUEST):
      return {
        ...state,
        toggleRequestError: actions.payload,
        toggleRequestPayload: null,
        toggleRequestLoading: false,
      };

    case REQUEST(actionTypes.GET_MERCHANT):
      return {
        ...state,
        getMerchantLoading: true,
        getMerchantPayload: null,
        getMerchantError: null,
      };
    case SUCCESS(actionTypes.GET_MERCHANT):
      return {
        ...state,
        getMerchantPayload: actions.payload,
        getMerchantError: null,
        getMerchantLoading: false,
      };
    case FAILURE(actionTypes.GET_MERCHANT):
      return {
        ...state,
        getMerchantError: actions.payload,
        getMerchantPayload: null,
        getMerchantLoading: false,
      };
    case REQUEST(actionTypes.TOGGLE_MERCHANT):
      return {
        ...state,
        toggleMerchantLoading: true,
        toggleMerchantPayload: null,
        toggleMerchantError: null,
      };
    case SUCCESS(actionTypes.TOGGLE_MERCHANT):
      return {
        ...state,
        toggleMerchantPayload: actions.payload,
        toggleMerchantError: null,
        toggleMerchantLoading: false,
      };
    case FAILURE(actionTypes.TOGGLE_MERCHANT):
      return {
        ...state,
        toggleMerchantError: actions.payload,
        toggleMerchantPayload: null,
        toggleMerchantLoading: false,
      };

    case REQUEST(actionTypes.POST_PARTNER):
      return {
        ...state,
        postPartnerLoading: true,
        postPartnerPayload: null,
        postPartnerError: null,
      };
    case SUCCESS(actionTypes.POST_PARTNER):
      return {
        ...state,
        postPartnerPayload: actions.payload,
        postPartnerError: null,
        postPartnerLoading: false,
      };
    case FAILURE(actionTypes.POST_PARTNER):
      return {
        ...state,
        postPartnerError: actions.payload,
        postPartnerPayload: null,
        postPartnerLoading: false,
      };
    case REQUEST(actionTypes.GET_COUNTRY):
      return {
        ...state,
        getCountryLoading: true,
        getCountryPayload: null,
        getCountryError: null,
      };
    case SUCCESS(actionTypes.GET_COUNTRY):
      return {
        ...state,
        getCountryPayload: actions.payload,
        getCountryError: null,
        getCountryLoading: false,
      };
    case FAILURE(actionTypes.GET_COUNTRY):
      return {
        ...state,
        getCountryError: actions.payload,
        getCountryPayload: null,
        getCountryLoading: false,
      };
    case REQUEST(actionTypes.GET_VIRTUAL_ACCOUNT_COUNTRIES):
      return {
        ...state,
        getVirtualAccountCountriesLoading: true,
        getVirtualAccountCountriesPayload: null,
        getVirtualAccountCountriesError: null,
      };
    case SUCCESS(actionTypes.GET_VIRTUAL_ACCOUNT_COUNTRIES):
      return {
        ...state,
        getVirtualAccountCountriesPayload: actions.payload,
        getVirtualAccountCountriesError: null,
        getVirtualAccountCountriesLoading: false,
      };
    case FAILURE(actionTypes.GET_VIRTUAL_ACCOUNT_COUNTRIES):
      return {
        ...state,
        getVirtualAccountCountriesError: actions.payload,
        getVirtualAccountCountriesPayload: null,
        getVirtualAccountCountriesLoading: false,
      };
    case REQUEST(actionTypes.GET_PROVIDER_BANKS):
      return {
        ...state,
        getProviderBanksLoading: true,
        getProviderBanksPayload: null,
        getProviderBanksError: null,
      };
    case SUCCESS(actionTypes.GET_PROVIDER_BANKS):
      return {
        ...state,
        getProviderBanksPayload: actions.payload,
        getProviderBanksError: null,
        getProviderBanksLoading: false,
      };
    case FAILURE(actionTypes.GET_PROVIDER_BANKS):
      return {
        ...state,
        getProviderBanksError: actions.payload,
        getProviderBanksPayload: null,
        getProviderBanksLoading: false,
      };
    case REQUEST(actionTypes.GET_TRANSACTION_RE_QUERY):
      return {
        ...state,
        getTransactionQueryByIdLoading: true,
        getTransactionQueryByIdPayload: null,
        getTransactionQueryByIdError: null,
      };
    case SUCCESS(actionTypes.GET_TRANSACTION_RE_QUERY):
      return {
        ...state,
        getTransactionQueryByIdPayload: actions.payload,
        getTransactionQueryByIdError: null,
        getTransactionQueryByIdLoading: false,
      };
    case FAILURE(actionTypes.GET_TRANSACTION_RE_QUERY):
      return {
        ...state,
        getTransactionQueryByIdError: actions.payload,
        getTransactionQueryByIdPayload: null,
        getTransactionQueryByIdLoading: false,
      };
    case REQUEST(actionTypes.GET_RECENT_TRANSACTIONS):
      return {
        ...state,
        getRecentTransactionLoading: true,
        getRecentTransactionPayload: null,
        getRecentTransactionError: null,
      };
    case SUCCESS(actionTypes.GET_RECENT_TRANSACTIONS):
      return {
        ...state,
        getRecentTransactionPayload: actions.payload,
        getRecentTransactionError: null,
        getRecentTransactionLoading: false,
      };
    case FAILURE(actionTypes.GET_RECENT_TRANSACTIONS):
      return {
        ...state,
        getRecentTransactionError: actions.payload,
        getRecentTransactionPayload: null,
        getRecentTransactionLoading: false,
      };
    case REQUEST(actionTypes.GET_CURRENCY):
      return {
        ...state,
        getCurrencyLoading: true,
        getCurrencyPayload: null,
        getCurrencyError: null,
      };
    case SUCCESS(actionTypes.GET_CURRENCY):
      return {
        ...state,
        getCurrencyPayload: actions.payload,
        getCurrencyError: null,
        getCurrencyLoading: false,
      };
    case FAILURE(actionTypes.GET_CURRENCY):
      return {
        ...state,
        getCurrencyError: actions.payload,
        getCurrencyPayload: null,
        getCurrencyLoading: false,
      };

    case REQUEST(actionTypes.GET_WALLET_CURRENCY):
      return {
        ...state,
        getWalletCurrencyLoading: true,
        getWalletCurrencyPayload: null,
        getWalletCurrencyError: null,
      };
    case SUCCESS(actionTypes.GET_WALLET_CURRENCY):
      return {
        ...state,
        getWalletCurrencyPayload: actions.payload,
        getWalletCurrencyError: null,
        getWalletCurrencyLoading: false,
      };
    case FAILURE(actionTypes.GET_WALLET_CURRENCY):
      return {
        ...state,
        getWalletCurrencyError: actions.payload,
        getWalletCurrencyPayload: null,
        getWalletCurrencyLoading: false,
      };

    case REQUEST(actionTypes.DELETE_CURRENCY):
      return {
        ...state,
        deleteCurrencyLoading: true,
        deleteCurrencyPayload: null,
        deleteCurrencyError: null,
      };
    case SUCCESS(actionTypes.DELETE_CURRENCY):
      return {
        ...state,
        deleteCurrencyPayload: actions.payload,
        deleteCurrencyError: null,
        deleteCurrencyLoading: false,
      };
    case FAILURE(actionTypes.DELETE_CURRENCY):
      return {
        ...state,
        deleteCurrencyError: actions.payload,
        deleteCurrencyPayload: null,
        deleteCurrencyLoading: false,
      };
    case REQUEST(actionTypes.ADD_CURRENCY):
      return {
        ...state,
        addCurrencyLoading: true,
        addCurrencyPayload: null,
        addCurrencyError: null,
      };
    case SUCCESS(actionTypes.ADD_CURRENCY):
      return {
        ...state,
        addCurrencyPayload: actions.payload.data,
        addCurrencyError: null,
        addCurrencyLoading: false,
      };
    case FAILURE(actionTypes.ADD_CURRENCY):
      return {
        ...state,
        addCurrencyError: actions.payload,
        addCurrencyPayload: null,
        addCurrencyLoading: false,
      };
    case REQUEST(actionTypes.TOGGLE_CURRENCY):
      return {
        ...state,
        toggleCurrencyLoading: true,
        toggleCurrencyPayload: null,
        toggleCurrencyError: null,
      };
    case SUCCESS(actionTypes.TOGGLE_CURRENCY):
      return {
        ...state,
        toggleCurrencyPayload: actions.payload,
        toggleCurrencyError: null,
        toggleCurrencyLoading: false,
      };
    case FAILURE(actionTypes.TOGGLE_CURRENCY):
      return {
        ...state,
        toggleCurrencyError: actions.payload,
        toggleCurrencyPayload: null,
        toggleCurrencyLoading: false,
      };
    case REQUEST(actionTypes.CONFIGURE_VA_MERCHANT):
      return {
        ...state,
        mutatingMerchantConfig: true,
        mutateMerchantConfigPayload: null,
        mutateMerchantConfigError: null,
      };
    case SUCCESS(actionTypes.CONFIGURE_VA_MERCHANT):
      return {
        ...state,
        mutateMerchantConfigPayload: actions.payload,
        mutateMerchantConfigError: null,
        mutatingMerchantConfig: false,
      };
    case FAILURE(actionTypes.CONFIGURE_VA_MERCHANT):
      return {
        ...state,
        mutateMerchantConfigError: actions.payload,
        mutateMerchantConfigPayload: null,
        mutatingMerchantConfig: false,
      };
    case REQUEST(actionTypes.FETCH_VA_MERCHANT_CONFIGS):
      return {
        ...state,
        merchantConfigs: null,
        fetchingMerchantConfigs: true,
        merchantConfigsError: null,
      };
    case SUCCESS(actionTypes.FETCH_VA_MERCHANT_CONFIGS):
      return {
        ...state,
        fetchingMerchantConfigs: false,
        merchantConfigsError: null,
        merchantConfigs: actions.payload,
      };
    case FAILURE(actionTypes.FETCH_VA_MERCHANT_CONFIGS):
      return {
        ...state,
        merchantConfigsError: actions.payload,
        fetchingMerchantConfigs: false,
        merchantConfigs: null,
      };
    case REQUEST(actionTypes.DOWNLOAD_TRANSACTION):
      return {
        ...state,
        downloadingTransaction: true,
        downloadTransactionPayload: null,
        downloadTransactionError: null,
      };
    case SUCCESS(actionTypes.DOWNLOAD_TRANSACTION):
      return {
        ...state,
        downloadTransactionPayload: actions.payload,
        downloadTransactionError: null,
        downloadingTransaction: false,
      };
    case FAILURE(actionTypes.DOWNLOAD_TRANSACTION):
      return {
        ...state,
        downloadTransactionError: actions.payload,
        downloadTransactionPayload: actions.payload,
        downloadingTransaction: false,
      };
    default:
      return { ...state };
  }
};

export default virtualAccountReducer;
