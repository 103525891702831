import actionTypes from "./actionTypes";
import { createAsyncAction } from "redux-promise-middleware-actions";
import {
  Delete,
  Get,
  GetFile,
  Post,
  Put,
} from "apiServices/apiHandler.service";
import {
  complianceBaseUrl,
  serviceManagerClientUrl,
  virtualAccountBaseUrl,
} from "apiServices/config.service";

export const getFx = createAsyncAction(
  actionTypes.TRANSACTION,
  async (start, end, pageSize = 10, pageNumber = 1) => {
    return await GetFile(
      `Admin/platformcharge?pageSize=${pageSize}&pageNumber=${pageNumber}&isAscending=false`,
      virtualAccountBaseUrl
    );
  }
);
export const getTransaction = createAsyncAction(
  actionTypes.TRANSACTION,
  async (start, end, pageSize = 10, pageNumber = 1) => {
    return await GetFile(
      `v1/Admin/reports/creditnotifications?StartDate=${start}&EndDate=${end}`,
      virtualAccountBaseUrl
    );
  }
);
export const getChargeBack = createAsyncAction(
  actionTypes.GET_CHARGE_BACK,
  async ({
    startDate,
    endDate,
    pageSize = 10,
    pageNumber = 1,
    status,
    keyword,
    merchantId,
  }) => {
    return await Get(
      `v1/admin/chargeback/getall?pageSize=${pageSize}&pageNumber=${pageNumber}${
        merchantId ? "&merchantId=" + merchantId : ""
      }${startDate ? "&startDate=" + startDate : ""}${
        endDate ? "&endDate=" + endDate : ""
      }${status ? "&status=" + status : ""}${
        keyword ? "&keyword=" + keyword : ""
      }`,
      virtualAccountBaseUrl
    );
  }
);
export const postChargeBack = createAsyncAction(
  actionTypes.POST_CHARGE_BACK,
  async (data) => {
    return await Post(`v1/admin/chargeback`, data, virtualAccountBaseUrl);
  }
);

export const getPartner = createAsyncAction(
  actionTypes.GET_PARTNER,
  async (pageNumber = 1, pageSize = 10, keyword = "") => {
    return await Get(
      `v1/Admin/getallproviders?pageSize=${pageSize}&pageNumber=${pageNumber}&keyword=${keyword}&IsAscending=false`,
      virtualAccountBaseUrl
    );
  }
);

export const togglePartner = createAsyncAction(
  actionTypes.TOGGLE_PARTNER,
  async (id) => {
    return await Put(
      `v1/Admin/toggleprovider/${id}`,
      {},
      virtualAccountBaseUrl
    );
  }
);
export const getRequest = createAsyncAction(
  actionTypes.GET_REQUEST,
  async (pageNumber = 1, pageSize = 10, keyword = "") => {
    return await Get(
      `v1/Admin/getmerchantproviders?status=PENDING&pageSize=${pageSize}&pageNumber=${pageNumber}&keyword=${keyword}&IsAscending=false`,
      virtualAccountBaseUrl
    );
  }
);

export const toggleRequest = createAsyncAction(
  actionTypes.TOGGLE_REQUEST,
  async (id, status) => {
    const payload = {
      merchantProviderId: id,
      comments: "string",
      status: status ? "UNAPPROVED" : "APPROVED",
      // "status": 'APPROVED'
    };
    return await Put(
      `v1/Admin/approvemerchantprovider`,
      payload,
      virtualAccountBaseUrl
    );
  }
);
export const getMerchant = createAsyncAction(
  actionTypes.GET_MERCHANT,
  async (pageNumber = 1, pageSize = 10, keyword = "") => {
    return await Get(
      `v1/Admin/getallmerchants?pageSize=${pageSize}&pageNumber=${pageNumber}&keyword=${keyword}&IsAscending=false`,
      virtualAccountBaseUrl
    );
  }
);
export const getSettlement = createAsyncAction(
  actionTypes.GET_SETTLEMENT,
  async (pageNumber = 1, pageSize = 10, keyword = "") => {
    return await Get(
      `v1/Admin/getcreditnotifications?pageSize=${pageSize}&pageNumber=${pageNumber}&keyword=${keyword}&IsAscending=false`,
      virtualAccountBaseUrl
    );
  }
);

export const fetchAllSettlements = createAsyncAction(
  actionTypes.GET_ALL_SETTLEMENTS,
  async ({ pageNumber = 1, pageSize = 10, keyword = "" }) => {
    return await Get(
      `v1/admin/getSettledTransactions?pageSize=${pageSize}&pageNumber=${pageNumber}&keyword=${keyword}&IsAscending=false`,
      virtualAccountBaseUrl
    );
  }
);

export const fetchAllUnSettledTransactions = createAsyncAction(
  actionTypes.GET_UNSETTLED_TRANSACTIONS,
  async (
    currency,
    startDate,
    endDate,
    pageNumber = 1,
    pageSize = 10,
    keyword = ""
  ) => {
    return await Get(
      `v1/admin/getUnSettledTransactions?currency=${currency}&StartDate=${startDate}&EndDate=${endDate}&pageSize=${pageSize}&pageNumber=${pageNumber}&keyword=${keyword}&IsAscending=false`,
      virtualAccountBaseUrl
    );
  }
);

export const fetchAllMerchantTransactions = createAsyncAction(
  actionTypes.GET_MERCHANT_TRANSACTIONS,
  async ({ merchantId, currency, pageNumber, keyword, startDate, endDate }) => {
    return await Get(
      `v1/admin/getTransactionsmerchantId/${merchantId}?pageSize=20${
        currency ? "&Currency=" + currency : ""
      }${startDate ? "&StartDate=" + startDate : ""}${
        endDate ? "&EndDate=" + endDate : ""
      }&pageNumber=${pageNumber}${
        keyword ? "&keyword=" + keyword : ""
      }&IsAscending=false`,
      virtualAccountBaseUrl
    );
  }
);

export const fetchAllBatchTransactions = createAsyncAction(
  actionTypes.GET_BATCH_TRANSACTIONS,
  async ({ batchId, pageNumber, keyword, startDate, endDate, currency }) => {
    return await Get(
      `v1/admin/getTransactionsbatchId/${batchId}?pageSize=20&Currency=${currency}${
        startDate ? "&StartDate=" + startDate : ""
      }${endDate ? "&EndDate=" + endDate : ""}&pageNumber=${pageNumber}${
        keyword ? "&keyword=" + keyword : ""
      }&IsAscending=false`,
      virtualAccountBaseUrl
    );
  }
);

export const postCompleteSettlement = createAsyncAction(
  actionTypes.POST_COMPLETE_SETTLEMENT,
  async (data) => {
    return await Post(
      `v1/Admin/completeSettlement`,
      data,
      virtualAccountBaseUrl
    );
  }
);

export const toggleMerchant = createAsyncAction(
  actionTypes.TOGGLE_MERCHANT,
  async (id) => {
    return await Put(
      `v1/Admin/togglemerchant/${id}`,
      {},
      virtualAccountBaseUrl
    );
  }
);

export const postPartner = createAsyncAction(
  actionTypes.POST_PARTNER,
  async (data) => {
    return await Post(`v1/Admin/createprovider`, data, virtualAccountBaseUrl);
  }
);

export const fetchVirtualAccountMerchantConfigs = createAsyncAction(
  actionTypes.FETCH_VA_MERCHANT_CONFIGS,
  async ({ merchantId, currencyCode }) => {
    return await Get(
      `v1/admin/getmerchantConfig/${merchantId}?currency=${currencyCode}`,
      virtualAccountBaseUrl
    );
  }
);

export const fetchVirtualAccountCountries = createAsyncAction(
  actionTypes.FETCH_PAYOUT_COUNTRIES,
  async () => {
    return await Get(
      `v1/admin/country/getlist?PageNumber=1&PageSize=300`,
      virtualAccountBaseUrl
    );
  }
);

export const configureVirtualAccountMerchant = createAsyncAction(
  actionTypes.CONFIGURE_VA_MERCHANT,
  async ({ payload, id, merchantId }) => {
    if (!id) {
      return await Post(
        `v1/admin/create_merchantconfig`,
        { ...payload, merchantId },
        virtualAccountBaseUrl
      );
    } else {
      return await Put(
        `v1/admin/update_merchantconfig`,
        { ...payload, id },
        virtualAccountBaseUrl
      );
    }
  }
);

export const getCountry = createAsyncAction(
  actionTypes.GET_COUNTRY,
  async () => {
    return await Get(
      "Country/GetAll?pageSize=1000&pageNumber=1&isAscending=false",
      serviceManagerClientUrl
    );
  }
);

export const getVirtualAccountCountries = createAsyncAction(
  actionTypes.GET_VIRTUAL_ACCOUNT_COUNTRIES,
  async () => {
    return await Get("v1/admin/getcountrylist", virtualAccountBaseUrl);
  }
);

export const getProviderBanks = createAsyncAction(
  actionTypes.GET_PROVIDER_BANKS,
  async (country) => {
    return await Get(
      `v1/admin/getallproviders?pageSize=10&pageNumber=1&keyword=${country}&IsAscending=false`,
      virtualAccountBaseUrl
    );
  }
);

export const getTransactionReQuery = createAsyncAction(
  actionTypes.GET_TRANSACTION_RE_QUERY,
  async ({ sessionId, accountNumber, providerBankId }) => {
    return await Get(
      `v1/admin/gettransaction?sessionId=${sessionId}&providerId=${providerBankId}&accountNumber=${accountNumber}`,
      virtualAccountBaseUrl
    );
  }
);

export const getRecentTransactions = createAsyncAction(
  actionTypes.GET_RECENT_TRANSACTIONS,
  async ({ accountNumber, country, providerBankId }) => {
    return await Get(
      `v1/admin/getcreditnotifications?Keyword=${accountNumber}&Currency=${country}&providerId=${providerBankId}`,
      virtualAccountBaseUrl
    );
  }
);

export const downloadTransaction = createAsyncAction(
  actionTypes.DOWNLOAD_TRANSACTION,
  async (data) => {
    return await GetFile(
      `v1/admin/reports/download?Provider=${data.providerBank}${
        data.accountNumber ? `&AccountNumber=${data.accountNumber}` : ""
      }&StartDate=${data.startDate}&EndDate=${data.endDate}`,
      virtualAccountBaseUrl
    );
  }
);

//'sis
export const getCurrency = createAsyncAction(
  actionTypes.GET_CURRENCY,
  async (pageSize, pageNumber) => {
    return await Get(
      `v1/Currency/GetAll?pageSize=${pageSize || 1000}&pageNumber=${
        pageNumber || 1
      }`,
      complianceBaseUrl
    );
  }
);

//'sis
export const getWalletCurrency = createAsyncAction(
  actionTypes.GET_WALLET_CURRENCY,
  async (pageSize, pageNumber) => {
    return await Get(
      `v1/Currency/wallet?pageSize=${pageSize || 1000}&pageNumber=${
        pageNumber || 1
      }&isAscending=false`,
      complianceBaseUrl
    );
  }
);

export const createCurrency = createAsyncAction(
  actionTypes.ADD_CURRENCY,
  async (data) => {
    return await Post(`v1/Currency`, data, complianceBaseUrl);
  }
);

export const toggleCurrency = createAsyncAction(
  actionTypes.TOGGLE_CURRENCY,
  async (id) => {
    return await Put(`v1/Currency/toggle/${id}`, {}, complianceBaseUrl);
  }
);

export const deleteCurrency = createAsyncAction(
  actionTypes.DELETE_CURRENCY,
  async (id) => {
    return await Delete(`v1/Currency/${id}`, complianceBaseUrl);
  }
);

const getPayloadFromData = (res) => {
  let result = {};
  const data = res.data;
  if (data) {
    /* Listed out possible scenarios of how the response data might be wrapped */
    const payload = data.data || data.result;
    result = payload || data;
  }
  return result;
};

const getDataFromResult = (res) => {
  if (Array.isArray(res)) {
    const result = [];
    res.map((obj) => result.push(getPayloadFromData(obj)));
    return result;
  }
  if (res) {
    return getPayloadFromData(res);
  }
};
/* Utility functions */

export function requestData(subtype) {
  return {
    type: actionTypes.BEGIN_REQUEST,
    subtype,
  };
}

export function requestSuccess(data) {
  return {
    type: actionTypes.REQUEST_SUCCESS,
    data,
  };
}
export function requestFailure(error) {
  return {
    type: actionTypes.REQUEST_FAILURE,
    error,
  };
}
/** Formerly known as fetchData */
export function promiseAction(apiRequest, action) {
  return (dispatch) => {
    dispatch({ type: actionTypes.BEGIN_REQUEST });
    apiRequest
      .then((res) => {
        action.payload = getDataFromResult(res);
        requestSuccess(action.payload);
        dispatch(action);
      })
      .catch((err) => {
        action.error = true; // We might want to submit the error messages
        action.payload = err.response || {};
        requestFailure(err);
        dispatch(action);
      });
  };
}
