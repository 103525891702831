import React, { useEffect, useState, useCallback } from "react";
import Switch from "react-switch";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import ListTable from "../../../components/Table/ListTable";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import FilterMenu from "../../../components/Input/FilterMenu";
import { clientsFilter } from "Constants";

import "../payout.scss";
import { getCurrency } from "logic/actions/requests";
import {
  fetchPayoutCountries,
  fetchPayoutMerchantConfig,
  fetchWalletCurrencies,
  getGateWay,
  getPayoutMerchant,
  mutatePayoutMerchant,
  toggleActivePayoutMerchant,
} from "logic/actions/payout";
import ToggleModal from "components/Modal/ToggleModal";
import Pagination from "../../../components/paginations/Paginations";
import { Button } from "reactstrap";
import CreateModal from "components/Modal/CreateModal";
import ManageMerchantForm from "./manageMerchantForm";
import { errorMessage, successMessage } from "logic/actions/notification";
import { PAYOUT_CHART_TYPES } from "utils/common";
import ViewModal from "components/Modal/ViewModal";

const initialFormValues = {
  chargeCurrency: "",
  chargeValue: "", // standard flat fee
  chargePercent: "", // standard percentage fee
  chargeCap: "", // standard cap
  localCharge: "", // local flat fee
  localPercent: "", // local percentage
  localCap: "", // local cap
  defaultPricing: "NONE",
  countryCode: "",
  payoutType: "", // Payment Channel
};

const Merchants = () => {
  const [id, setId] = useState(null);
  const [status, setStatus] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [toggleForm, setToggleForm] = useState(false);
  const [, setHasMapped] = useState(false);
  const [data, setData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const dispatch = useDispatch();
  const [editor, setEditor] = useState({
    open: false,
    payload: { ...initialFormValues },
    countries: [],
    step: 1,
  });
  const [viewDetails, setViewDetails] = useState({
    open: false,
    payload: { ...initialFormValues },
    countries: [],
    step: 1,
  });
  const [submitStatus, setSubmitStatus] = useState(false);

  const {
    getPayoutMerchantLoading,
    getPayoutMerchantPayload,
    toggleActivePayoutMerchantLoading,
    toggleActivePayoutMerchantPayload,
    merchantConfigsPayload,
    fetchingCountries,
    mutateMerchantPayload,
    mutateMerchantError,
    mutatingMerchant,
    payoutCountriesPayload,
    walletCurrencies,
    fetchingMerchantConfigs,
    getGateWayPayload,
  } = useSelector(({ payoutReducer, virtualAccountReducer }) => {
    return {
      getPayoutMerchantError: payoutReducer?.getPayoutMerchantError,
      getPayoutMerchantPayload: payoutReducer?.getPayoutMerchantPayload,
      getPayoutMerchantLoading: payoutReducer?.getPayoutMerchantLoading,
      toggleActivePayoutMerchantError:
        payoutReducer?.toggleActivePayoutMerchantError,
      toggleActivePayoutMerchantPayload:
        payoutReducer?.toggleActivePayoutMerchantPayload,
      toggleActivePayoutMerchantLoading:
        payoutReducer?.toggleActivePayoutMerchantLoading,
      mutateMerchantPayload: payoutReducer?.mutateMerchantPayload,
      mutatingMerchant: payoutReducer?.mutatingMerchant,
      mutateMerchantError: payoutReducer?.mutateMerchantError,
      merchantConfigsPayload: payoutReducer?.merchantConfigsPayload,
      fetchingMerchantConfigs: payoutReducer?.fetchingMerchantConfigs,
      payoutCountriesPayload: payoutReducer?.payoutCountriesPayload,
      walletCurrencies: payoutReducer?.walletCurrencies,
      getCountryError: virtualAccountReducer?.getCountryError,
      getCountryPayload: virtualAccountReducer?.getCountryPayload,
      getCountryLoading: virtualAccountReducer?.getCountryLoading,
      getCurrencyError: virtualAccountReducer?.getCurrencyError,
      getCurrencyPayload: virtualAccountReducer?.getCurrencyPayload,
      getCurrencyLoading: virtualAccountReducer?.getCurrencyLoading,
      getGateWayPayload: payoutReducer?.getGateWayPayload,
    };
  });

  const getPaginationNumber = (page) => {
    setCurrentPage(page);
    dispatch(getPayoutMerchant(1, 10, page, searchValue));
  };

  useEffect(() => {
    dispatch(getPayoutMerchant(1));
    dispatch(getCurrency());
    dispatch(fetchPayoutCountries());
    dispatch(fetchWalletCurrencies());
    dispatch(getGateWay());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      !fetchingMerchantConfigs &&
      merchantConfigsPayload?.data &&
      !merchantConfigsPayload?.data?.hasError
    ) {
      const data = merchantConfigsPayload?.data?.responseData;
      const payload = {
        ...editor.payload,

        merchantStatus:
          data?.merchantStatus || editor.payload.merchantStatus || "",
        countryCode: data?.countryCode || editor.payload.countryCode || "",
        merchantName: data?.merchantName || editor.payload.merchantName || "",
        chargeCurrency: data?.chargeCurrency || "",
        chargeValue: +data?.chargeValue || "",
        localCharge: +data?.localCharge || "",
        chargeCap: +data?.chargeCap || "",
        chargePercent: +data?.chargePercent || "",
        localPercent: +data?.localPercent || "",
        localCap: +data?.localCap || "",
        defaultPricing: data?.defaultPricing || "",
      };

      setEditor((prev) => ({ ...prev, payload, recordId: data?.id }));
      setHasMapped(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchingMerchantConfigs]);

  useEffect(() => {
    if (!mutatingMerchant) {
      if (
        mutateMerchantPayload?.data?.responseData &&
        mutateMerchantPayload?.data?.requestSuccessful &&
        editor.submitted
      ) {
        setEditor({
          open: false,
          payload: {},
          currencies: [],
          step: 1,
          submittteded: false,
        });
        setSubmitStatus(false);
        dispatch(
          successMessage(
            mutateMerchantPayload?.data?.message || "Record saved successfully"
          )
        );
        dispatch(getPayoutMerchant(1));
      }

      if (
        !mutateMerchantPayload?.data?.responseData &&
        !mutateMerchantPayload?.data?.requestSuccessful &&
        editor.submitted
      ) {
        dispatch(
          errorMessage(
            mutateMerchantPayload?.data?.message || "Record saved successfully"
          )
        );
        setSubmitStatus(false);
        setEditor((prev) => ({ ...prev, submitted: false }));
      }

      if (
        Array.isArray(mutateMerchantError?.data?.errors) &&
        mutateMerchantError?.data?.errors?.length &&
        editor.submitted
      ) {
        const errors = mutateMerchantError?.data?.errors;
        for (let error of errors) {
          dispatch(errorMessage(error));
        }
        setEditor((prev) => ({ ...prev, submitted: false }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutatingMerchant]);

  useEffect(() => {
    if (
      !getPayoutMerchantLoading &&
      getPayoutMerchantPayload?.data?.requestSuccessful
    ) {
      setData(getPayoutMerchantPayload?.data?.responseData?.items);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPayoutMerchantLoading]);

  useEffect(() => {
    if (
      !toggleActivePayoutMerchantLoading &&
      toggleActivePayoutMerchantPayload
    ) {
      let i = 0;
      for (let dt of data) {
        if (dt?.id === id) {
          data[i]["isActive"] = !dt?.isActive;
          setData([...data]);
          setToggleForm(false);
          return;
        }
        i++;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleActivePayoutMerchantLoading]);

  const handleChange = (statusId, checked) => {
    setId(statusId);
    setStatus(checked);
    setToggleForm(true);
  };

  const debounceText = useCallback(
    debounce(async (srhTxt) => {
      dispatch(getPayoutMerchant(1, 10, 1, srhTxt));
    }, 500),
    []
  );

  const handleSearchChange = (event, input) => {
    debounceText(event.target.value);
    setSearchValue(event.target.value);
  };

  const clearSearch = () => {
    debounceText("");
    setSearchValue("");
  };

  const showFilterMenu = () => {};

  const handleAutoCompleteCreateChange = (event, values, input) => {};

  const handleManageClick = (row) => {
    setEditor((prev) => ({
      ...prev,
      payload: {
        merchantID: row.id,
        merchantStatus: row.status,
        merchantName: row.merchantName,
        countryCode: row.country,
        ...initialFormValues,
      },
      open: true,
      merchantName: row.merchantName,
    }));
  };
  
  const handleViewClick = (row) => {
    setViewDetails((prev) => ({
      ...prev,
      payload: {
        ...row,
      },
      open: true,
      merchantName: row.merchantName,
    }));
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    if (name === "countryCode") {
      setHasMapped(true);
    }
    setEditor((prev) => ({
      ...prev,
      payload: { ...prev.payload, [name]: value },
    }));
  };

  const format = () => {
    return data?.map((item, index) => {
      return {
        name: item?.merchantName,
        Id: item?.id,
        wallet: item?.mifosWalletId,
        date: item?.createdAt,
        country: item?.country,
        currency: item?.currency,
        isActive: item?.isActive,
        Actions: (
          <div className="d-flex align-items-center justify-content-start">
            <Button
              size="sm"
              color="link"
              className="text-primary pl-0 font-weight-bold"
              onClick={() => handleViewClick(item)}
            >
              View
            </Button>
            <Button
              size="sm"
              color="link"
              className="text-primary pl-0 font-weight-bold"
              onClick={() => handleManageClick(item)}
            >
              Manage
            </Button>
            <Switch
              onChange={() => handleChange(item?.id, item?.isActive)}
              onColor="#175FFF"
              checked={item?.isActive}
              uncheckedIcon={false}
              checkedIcon={false}
            />
          </div>
        ),
      };
    });
  };

  const submit = () => {
    let errorStatus = false;
    if (editor.step === 1) {
      setEditor((prev) => ({ ...prev, step: 2 }));
      dispatch(
        fetchPayoutMerchantConfig({
          merchantId: editor.payload.merchantID,
          countryCode: editor.payload.countryCode,
        })
      );
    } else {
      for (let data in editor.payload) {
        if (!editor.payload[data] || editor.payload[data] === "") {
          errorStatus = true;
          setSubmitStatus(true);
        }
      }

      if (!errorStatus) {
        dispatch(
          mutatePayoutMerchant({
            payload: editor.payload,
            id: editor?.recordId,
          })
        );
        setEditor((prev) => ({ ...prev, submitted: true }));
      }
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          overflowY: "hidden",
        }}
        className="customScroll"
      >
        <FilterMenu
          caption="Clients List"
          totalCount={10}
          searchLabel="Search.."
          filterOptions={clientsFilter}
          filterdefaultValueOptions={{
            label: "All",
            value: "all",
          }}
          handleAutoCompleteCreateChange={handleAutoCompleteCreateChange}
          showFilter={false}
          showSearch={true}
          showCreateBtn={false}
          btnName=""
          openCreate={() => {}}
          handleSearchChange={handleSearchChange}
          showFilterMenu={showFilterMenu}
          filterLabel="Filter by"
          searchName="keyword"
          searchValue={searchValue}
          clearSearch={clearSearch}
          entriesName="Entries"
          filterValue={"Search"}
          entriesOptions={{}}
          entriesdefaultValueOptions={{
            label: `Entries`,
            value: 20,
          }}
          entriesValue={{}}
          entriesLabel="Entries"
        />
      </div>

      <ListTable
        header={[
          { name: "Name" },
          { name: "Currency" },
          { name: "Country" },
          { name: "Date" },
          { name: "Status" },
          { name: "Action" },
        ]}
        isSearching={getPayoutMerchantLoading}
        showError={false}
        style={{ opacity: 1 }}
        pageOfItems={data}
        content={
          <TableBody>
            {format()?.map((row, index) => (
              <TableRow key={index} style={{ cursor: "pointer" }}>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.currency}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.country}
                </TableCell>
                <TableCell align="left">
                  {new Date(row.date).toLocaleDateString()}
                </TableCell>
                <TableCell align="left">
                  <div className="status-flag">
                    <span
                      className={`dot-status ${
                        row.isActive ? "dot-active" : "dot-inactive"
                      }`}
                    >
                      .
                    </span>
                    {row.isActive ? "Active" : "Inactive"}
                  </div>
                </TableCell>
                <TableCell>{row.Actions}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        }
      />
      <br />
      {data?.length > 0 ? (
        <div className="paginate-content">
          <div>
            Showing Page{" "}
            {getPayoutMerchantPayload?.data?.responseData?.pageNumber} of{" "}
            {getPayoutMerchantPayload?.data?.responseData?.pages}
          </div>
          <div>
            <Pagination
              totalPages={getPayoutMerchantPayload?.data?.responseData?.pages}
              currentPage={currentPage}
              changeCurrentPage={(p) => getPaginationNumber(p)}
              previousBtn="Prev"
              nextBtn="Next"
            />
          </div>
        </div>
      ) : null}

      {toggleForm && (
        <ToggleModal
          title={status ? "Disable item?" : "Enable Item?"}
          toggleForm={toggleForm}
          onToggle={() => dispatch(toggleActivePayoutMerchant(id))}
          toggling={toggleActivePayoutMerchantLoading}
          disableToggle={toggleActivePayoutMerchantLoading}
          hideToggleModal={() => setToggleForm(false)}
          toggleText={status ? "Disable" : "Enable"}
          textContent={
            status
              ? "Are you sure you want to disable this item?"
              : "Are you sure you want to enable this item?"
          }
        />
      )}

      {viewDetails.open &&<ViewModal
        open={viewDetails.open}
        content={viewDetails.payload}
        hideViewForm={() =>
          setViewDetails({...viewDetails, open: false})
        }
        title="Merchant Details"
      />}
      {editor.open && (
        <CreateModal
          fetchingMerchantConfigs={fetchingMerchantConfigs}
          maxWidth="lg"
          handleCancelFromParent={editor.step > 1}
          onCancelClick={() => setEditor((prev) => ({ ...prev, step: 1 }))}
          title="Manage Merchant"
          createForm={editor.open}
          hideCreateForm={() =>
            setEditor({ open: false, payload: {}, countries: [], step: 1 })
          }
          onCreate={() => submit()}
          creating={mutatingMerchant || fetchingMerchantConfigs}
          disableCreate={
            mutatingMerchant ||
            (editor.step === 1 &&
              (!editor.payload.countryCode || !editor.payload.payoutType))
          }
          cancelText="Cancel"
          createText={editor.step > 1 ? "Add" : "Continue"}
          content={
            <ManageMerchantForm
              step={editor.step}
              payload={editor.payload}
              submitStatus={submitStatus}
              onChange={handleFormChange}
              loading={fetchingCountries}
              countries={
                payoutCountriesPayload?.data?.responseData?.map((v) => ({
                  label: v.countryName === "" ? "country name" : v.countryName,
                  value: v.countryCode,
                })) || []
              }
              chargeTypes={PAYOUT_CHART_TYPES}
              currencies={walletCurrencies?.data?.responseData?.items?.map(
                (v) => ({ label: v.name, value: v.currencyCode })
              )}
              gateways={getGateWayPayload?.data?.responseData?.items?.map(
                (val) => ({ value: val?.gatewayCode, label: val?.gatewayName })
              )}
              fetchingConfig={fetchingMerchantConfigs}
            />
          }
        />
      )}
    </>
  );
};

export default Merchants;
