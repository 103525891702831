import { appHelpers } from "appHelpers";

const actionTypes = {
  GET_SMART_CHECK_REQUEST: "GET_SMART_CHECK_REQUEST",
  APPROVE_SMART_CHECK_REQUEST: "APPROVE_SMART_CHECK_REQUEST",
  REJECT_SMART_CHECK_REQUEST: "REJECT_SMART_CHECK_REQUEST",

  GET_SMART_CHECK_MERCHANTS: "GET_SMART_CHECK_MERCHANTS",
  GET_SMART_CHECK_MERCHANTS_SUBSCRIPTIONS:
    "GET_SMART_CHECK_MERCHANTS_SUBSCRIPTIONS",
  UPDATE_SMART_CHECK_MERCHANTS_SUBSCRIPTIONS:
    "UPDATE_SMART_CHECK_MERCHANTS_SUBSCRIPTIONS",
  CREATE_SMART_CHECK_MERCHANTS_SUBSCRIPTIONS:
    "CREATE_SMART_CHECK_MERCHANTS_SUBSCRIPTIONS",
  TOGGLE_SMART_CHECK_MERCHANTS_STATUS: "TOGGLE_SMART_CHECK_COUNTRY_STATUS",

  UPDATE_SMART_CHECK_SUBSCRIPTIONS_PLAN:
    "UPDATE_SMART_CHECK_SUBSCRIPTIONS_PLAN",
  CREATE_SMART_CHECK_SUBSCRIPTIONS_PLAN:
    "CREATE_SMART_CHECK_SUBSCRIPTIONS_PLAN",
  GET_SMART_CHECK_SUBSCRIPTIONS_PLAN_LIST:
    "GET_SMART_CHECK_SUBSCRIPTIONS_PLAN_LIST",

  UPDATE_SMART_CHECK_SUBSCRIPTIONS_PRICING_LIST:
    "UPDATE_SMART_CHECK_SUBSCRIPTIONS_PRICING_LIST",
  GET_SMART_CHECK_SUBSCRIPTIONS_PLAN_PRICING_ID:
    "GET_SMART_CHECK_SUBSCRIPTIONS_PLAN_PRICING_ID",
  GET_SMART_CHECK_SUBSCRIPTIONS_PLAN: "GET_SMART_CHECK_SUBSCRIPTIONS_PLAN",
  CREATE_SMART_CHECK_SUBSCRIPTIONS_PRICING_LIST:
    "CREATE_SMART_CHECK_SUBSCRIPTIONS_PRICING_LIST",

  GET_SMART_CHECK_SERVICES: "GET_SMART_CHECK_SERVICES",
  UPDATE_SMART_CHECK_SERVICES: "UPDATE_SMART_CHECK_SERVICES",
  ADD_NEW_SMART_CHECK_SERVICES: "ADD_NEW_SMART_CHECK_SERVICES",

  //smartchek country action types
  GET_SMART_CHECK_COUNTRY_DOCS: "GET_SMART_CHECK_COUNTRY_DOCS",
  UPDATE_SMART_CHECK_COUNTRY_DOCS: "UPDATE_SMART_CHECK_COUNTRY_DOCS",
  ADD_NEW_SMART_CHECK_COUNTRY_DOCS: "ADD_NEW_SMART_CHECK_COUNTRY_DOCS",
  TOGGLE_SMART_CHECK_COUNTRY_DOCS_STATUS:
    "TOGGLE_SMART_CHECK_COUNTRY_DOCS_STATUS",

  //smartchek document action types
  GET_SMART_CHECK_DOCUMENTS: "GET_SMART_CHECK_DOCUMENTS",
  UPDATE_SMART_CHECK_DOCUMENT: "UPDATE_SMART_CHECK_DOCUMENTS",
  ADD_NEW_SMART_CHECK_DOCUMENT: "ADD_NEW_SMART_CHECK_DOCUMENTS",

  //transaction query action types
  GET_QUERY: "GET_QUERY",
  GET_TRANSACTION_RE_QUERY: "GET_TRANSACTION_RE_QUERY",
  GET_PROVIDER_BANKS: "GET_PROVIDER_BANKS",
  GET_VIRTUAL_ACCOUNT_COUNTRIES: "GET_VIRTUAL_ACCOUNT_COUNTRIES",
  GET_RECENT_TRANSACTIONS: "GET_RECENT_TRANSACTIONS",
  DOWNLOAD_TRANSACTION: "DOWNLOAD_TRANSACTION",
  // GET_COUNTRY: "GET_COUNTRY",

  GET_PARTNER: "GET_PARTNER",
  TOGGLE_PARTNER: "TOGGLE_PARTNER",
  POST_PARTNER: "POST_PARTNER",
  FETCH_VA_MERCHANT_CONFIGS: "FETCH_VA_MERCHANT_CONFIGS",
  CONFIGURE_VA_MERCHANT: "CONFIGURE_VA_MERCHANT",
  APP_LOAD: "APP_LOAD",
  GET_COUNTRY: "GET_COUNTRY",
  GET_SUPPORTED_COUNTRY: "GET_SUPPORTED_COUNTRY",
  GET_SUPPORTED_CURRENCY: "GET_SUPPORTED_CURRENCY",
  GET_CURRENCY: "GET_CURRENCY",
  FETCH_WALLET_CURRENCIES: "FETCH_WALLET_CURRENCIES",
  GET_WALLET_CURRENCY: "GET_WALLET_CURRENCY",
  GET_REQUEST: "GET_REQUEST",
  GET_MERCHANT: "GET_MERCHANT",
  TOGGLE_REQUEST: "TOGGLE_REQUEST",
  TOGGLE_MERCHANT: "TOGGLE_MERCHANT",
  TRANSACTION: "TRANSACTION",
  GET_IDENTITY_TYPES: "GET_IDENTITY_TYPES",
  GET_KYC_ENTITIES: "GET_KYC_ENTITIES",
  GET_SETTLEMENT: "GET_SETTLEMENT",
  GET_ALL_SETTLEMENTS: "GET_ALL_SETTLEMENTS",
  GET_UNSETTLED_TRANSACTIONS: "GET_UNSETTLED_TRANSACTIONS",
  GET_MERCHANT_TRANSACTIONS: "GET_MERCHANT_TRANSACTIONS",
  GET_BATCH_TRANSACTIONS: "GET_BATCH_TRANSACTIONS",
  POST_COMPLETE_SETTLEMENT: "POST_COMPLETE_SETTLEMENT",
  POST_KYC_ENTITIES: "POST_KYC_ENTITIES",
  GET_KYC_MERCHANT: "GET_KYC_MERCHANT",
  TOGGLE_KYC_REQUEST: "TOGGLE_KYC_REQUEST",
  TOGGLE_ACTIVE_KYC_REQUEST: "TOGGLE_ACTIVE_KYC_REQUEST",
  KYC_TRANSACTION_STATS: "KYC_TRANSACTION_STATS",
  GET_PAYOUT_ENTITIES: "GET_PAYOUT_ENTITIES",
  POST_PAYOUT_ENTITIES: "POST_PAYOUT_ENTITIES",
  GET_PAYOUT_MERCHANT: "GET_PAYOUT_MERCHANT",
  TOGGLE_PAYOUT_REQUEST: "TOGGLE_PAYOUT_REQUEST",
  POST_TRANSACTION_REQUERY: "POST_TRANSACTION_REQUERY",
  GET_TRANSACTION_DETAILS: "GET_TRANSACTION_DETAILS",
  GET_PAYOUT_COUNTRY: "GET_PAYOUT_COUNTRY",
  TOGGLE_ACTIVE_PAYOUT_REQUEST: "TOGGLE_ACTIVE_PAYOUT_REQUEST",
  GET_GATEWAY: "GET_GATEWAY",
  POST_PAYOUT_COUNTRY: "POST_PAYOUT_COUNTRY",
  FETCH_PAYOUT_MERCHANT_CONFIGS: "FETCH_PAYOUT_MERCHANT_CONFIGS",
  FETCH_PAYOUT_COUNTRIES: "FETCH_PAYOUT_COUNTRIES",
  CREATE_PAYOUT_GATEWAY: "CREATE_PAYOUT_GATEWAY",
  TOGGLE_PAYOUT_GATEWAY: "TOGGLE_PAYOUT_GATEWAY",
  MUTATE_PAYOUT_MERCHANT: "MUTATE_PAYOUT_MERCHANT",
  TOGGLE_COUNTRY_PAYOUT: "TOGGLE_COUNTRY_PAYOUT",
  GET_SWIFT_PAYOUT: "GET_SWIFT_PAYOUT",
  UPDATE_SWIFT_PAYOUT_STATUS: "UPDATE_SWIFT_PAYOUT_STATUS",
  GET_COMPLIANCE_MERCHANTS: "GET_COMPLIANCE_MERCHANTS",
  GET_COMPLIANCE_PROFILE: "GET_COMPLIANCE_PROFILE",
  GET_COMPLIANCE_PROFILE_BY_ID: "GET_COMPLIANCE_PROFILE_BY_ID",
  GET_COMPLIANCE_DOC_BY_REFERENCE: "GET_COMPLIANCE_DOC_BY_REFERENCE",
  GET_COMPLIANCE_DOC: "GET_COMPLIANCE_DOC",
  GET_PROFILE_COMPLIANCE_DOC: "GET_PROFILE_COMPLIANCE_DOC",
  COMPLIANCE_APPROVE: "COMPLIANCE_APPROVE",
  GET_CLIENT_DETAILS: "GET_CLIENT_DETAILS",
  GET_PROFILE_DETAILS: "GET_PROFILE_DETAILS",
  GET_CHARGE_BACK: "GET_CHARGE_BACK",
  POST_CHARGE_BACK: "POST_CHARGE_BACK",
  POST_FX: "POST_FX",
  GET_FX: "GET_FX",
  TOGGLE_FX: "TOGGLE_FX",
  GET_PAST_FX: "GET_PAST_FX",
  GET_CHANNEL: "GET_CHANNEL",
  POST_CHANNEL: "POST_CHANNEL",
  PUT_CHANNEL: "PUT_CHANNEL",
  GET_COLLECT_MERCHANT: "GET_COLLECT_MERCHANT",
  POST_COLLECT_MERCHANT: "POST_COLLECT_MERCHANT",
  TOGGLE_COLLECT_MERCHANT: "TOGGLE_COLLECT_MERCHANT",
  POST_COLLECT_CHARGEBACK: "POST_COLLECT_CHARGEBACK",
  GET_COLLECT_CHARGEBACK: "GET_COLLECT_CHARGEBACK",
  TOGGLE_COLLECT_CHANNEL: "TOGGLE_COLLECT_CHANNEL",
  MANAGE_COLLECT_MERCHANT: "MANAGE_COLLECT_MERCHANT",
  GET_COLLECT_COUNTRY: "GET_COLLECT_COUNTRY",
  POST_COLLECT_COUNTRY: "POST_COLLECT_COUNTRY",

  BEGIN_REQUEST: "BEGIN_REQUEST",
  COLLAPSE_MENU: "COLLAPSE_MENU",
  COLLAPSE_TOGGLE: "COLLAPSE_TOGGLE",
  FULL_SCREEN: "FULL_SCREEN",
  FULL_SCREEN_EXIT: "FULL_SCREEN_EXIT",
  CHANGE_LAYOUT: "CHANGE_LAYOUT",
  NAV_CONTENT_LEAVE: "NAV_CONTENT_LEAVE",
  NAV_COLLAPSE_LEAVE: "NAV_COLLAPSE_LEAVE",
  UPDATE_NAV_HEADER_COLOR: "UPDATE_NAV_HEADER_COLOR",
  CHANGE_TOP_NAV_MENU_ITEM: "CHANGE_TOP_NAV_MENU_ITEM",
  TOGGLE_CHART_AND_DATA_UI: "TOGGLE_CHART_AND_DATA_UI",
  GET_MICROSERVICES_SUCCESS: "GET_MICROSERVICES_SUCCESS",
  GET_PAYMENT_SERVICE_GROUPS_SUCCESS: "GET_PAYMENT_SERVICE_GROUPS_SUCCESS",
  GET_COUNTRIES_SUCCESS: "GET_COUNTRIES_SUCCESS",
  // GET_COUNTRY_SUCCESS: "GET_COUNTRY_SUCCESS",
  GET_CLIENTSERVICES_SUCCESS: "GET_CLIENTSERVICES_SUCCESS",
  GET_CLIENT_CONFIGURATIONS: "GET_CLIENT_CONFIGURATIONS",
  GET_GATEWAYS_SUCCESS: "GET_GATEWAYS_SUCCESS",
  GET_GATEWAY_CREDENTIAL_KEY_SUCCESS: "GET_GATEWAY_CREDENTIAL_KEY_SUCCESS",
  GET_CLIENTS_SUCCESS: "GET_CLIENTS_SUCCESS",
  GET_SERVICES_SUCCESS: "GET_SERVICES_SUCCESS",
  GET_ALL_CONFIGURATIONS_SUCCESS: "GET_ALL_CONFIGURATIONS_SUCCESS",
  GET_RESPONSE_CODE_SUCCESS: "GET_RESPONSE_CODE_SUCCESS",
  GET_GATEWAY_ACCOUNTS_BY_CLIENT: "GET_GATEWAY_ACCOUNTS_BY_CLIENT",
  GET_CURRENCY_PAIR_SUCCESS: "GET_CURRENCY_PAIR_SUCCESS",
  GET_CURRENCY_SUCCESS: "GET_CURRENCY_SUCCESS",
  GET_PERMISSIONS_SUCCESS: "GET_PERMISSIONS_SUCCESS",
  GET_BANK_SUCCESS: "GET_BANK_SUCCESS",
  SAVE_USER_SUCCESS: "SAVE_USER_SUCCESS",
  GET_ROLES_SUCCESS: "GET_ROLES_SUCCESS",
  GET_USER_SUCCESS: "GET_USER_SUCCESS",
  GET_GATEWAYS_BY_CLIENT: "GET_GATEWAYS_BY_CLIENT",
  SET_EXPIRATION: "SET_EXPIRATION",
  GET_PLATFORM_CHARGE_TYPES_SUCCESS: "GET_PLATFORM_CHARGE_TYPES_SUCCESS",
  GET_GATEWAY_CHARGE_TYPES_SUCCESS: "GET_GATEWAY_CHARGE_TYPES_SUCCESS",
  GET_STATES_SUCCESS: "GET_STATES_SUCCESS",
  GET_KYC_LEVELS_SUCCESS: "GET_KYC_LEVELS_SUCCESS",
  GET_GATEWAY_CHARGE_BEARER_SUCCESS: "GET_GATEWAY_CHARGE_BEARER_SUCCESS",
  GET_SETTLEMENT_TRANSACTIONS_PENDING: "GET_SETTLEMENT_TRANSACTIONS_PENDING",
  GET_SETTLEMENT_TRANSACTIONS_SUCCESS: "GET_SETTLEMENT_TRANSACTIONS_SUCCESS",
  POST_SETTLEMENT_TRANSACTIONS_PENDING: "POST_SETTLEMENT_TRANSACTIONS_PENDING",
  POST_SETTLEMENT_TRANSACTIONS_SUCCESS: "POST_SETTLEMENT_TRANSACTIONS_SUCCESS",
  POST_SETTLEMENT_TRANSACTIONS_ERROR: "POST_SETTLEMENT_TRANSACTIONS_ERROR",
  SAVE_CURRENT_CLIENT_SUCCESS: "SAVE_CURRENT_CLIENT_SUCCESS",
  SAVE_CLIENT_NAVIGATION_KEY: "SAVE_CLIENT_NAVIGATION_KEY",
  SAVE_TEST_DATA_SUCCESS: "SAVE_TEST_DATA_SUCCESS",
  SAVE_CURRENT_GATEWAY_SUCCESS: "SAVE_CURRENT_GATEWAY_SUCCESS",

  DELETE_CURRENCY: "DELETE_CURRENCY",
  ADD_CURRENCY: "ADD_CURRENCY",
  TOGGLE_CURRENCY: "TOGGLE_CURRENCY",

  GET_ALL_CARDS_MERCHANTS: "GET_ALL_CARDS_MERCHANTS",
  TOGGLE_CARDS_MERCHANT: "TOGGLE_CARDS_MERCHANT",
  TOGGLE_CARDS_REQUEST: "TOGGLE_CARDS_REQUEST",
  UPSERT_CARDS_CURRENCY: "UPSERT_CARDS_CURRENCY",
  UPSERT_CARDS_MERCHANT: "UPSERT_CARDS_MERCHANT",
  FETCH_CARDS_CURRENCIES: "FETCH_CARDS_CURRENCIES",
  GET_ALL_CARDS_REQUESTS: "GET_ALL_CARDS_REQUESTS",
  FETCH_CARDS_MERCHANT_CONFIG: "FETCH_CARDS_MERCHANT_CONFIG",

  FETCH_KYC_MERCHANT_ENTITIES: "FETCH_KYC_MERCHANT_ENTITIES",
  MUTATE_KYC_MERCHANT: "MUTATE_KYC_MERCHANT",
  GET_KYC_ONBOARDED_COUNTRIES: "GET_KYC_ONBOARDED_COUNTRIES",
  FETCH_KYC_MERCHANT_CONFIGS: "FETCH_KYC_MERCHANT_CONFIGS",
  FETCH_KYC_MERCHANT_CONFIG: "FETCH_KYC_MERCHANT_CONFIG",

  TOGGLE_FEATURE_FLAG: "TOGGLE_FEATURE_FLAG",
  CREATE_FEATURE_FLAG: "CREATE_FEATURE_FLAG",
  GET_FEATURE_FLAGS: "GET_FEATURE_FLAGS",

  FETCH_USER_ROLES: "FETCH_USER_ROLES",
  MUTATE_USER_ROLE: "MUTATE_USER_ROLE",
  UPDATE_USER: "UPDATE_USER",
  CREATE_USER: "CREATE_USER",
  GET_USERS: "GET_USERS",
  ADD_USER: "ADD_USER",
  TOGGLE_USER: "TOGGLE_USER",
  GET_ROLES: "GET_ROLES",
  ADD_ROLES: "ADD_ROLES",
  UPDATE_ROLES: "UPDATE_ROLES",

  GET_ALL_MERCHANTS: "GET_ALL_MERCHANTS",
  GET_MERCHANT_INFO: "GET_ALL_MERCHANT_INFO",
  TOGGLE_MERCHANT_STATUS: "TOGGLE_MERCHANT",
  WITHDRAW_MERCHANT: "WITHDRAW_MERCHANT",
  DEPOSIT_MERCHANT: "DEPOSIT_MERCHANT",
  GET_TRANSFERS: "GET_TRANSFERS",
  APPROVE_TRANSFER: "APPROVE_TRANSFER",
  POST_TRANSFERS: "POST_TRANSFERS",
  GET_BANKS: "GET_BANKS",
  ACCOUNT_LOOKUP: "ACCOUNT_LOOKUP",
};
// add as many actionTypes as necessary. (But be careful of memory leakages)
export const saveClientNavigationKeySuccess = (clientNavigationKey) => ({
  type: actionTypes.SAVE_CLIENT_NAVIGATION_KEY,
  payload: { clientNavigationKey },
});

export const saveexpirationSuccess = (expiresIn) => ({
  type: actionTypes.SET_EXPIRATION,
  payload: { expiresIn },
});
export const saveuserDataSuccess = (userdata) => ({
  type: actionTypes.SAVE_USER_SUCCESS,
  payload: { userdata },
});

export const savecurrentClientSuccess = (currentBusiness) => ({
  type: actionTypes.SAVE_CURRENT_CLIENT_SUCCESS,
  payload: { currentBusiness },
});
export const savetestDataSuccess = (testData) => ({
  type: actionTypes.SAVE_TEST_DATA_SUCCESS,
  payload: { testData },
});
export const savecurrentGatewaySuccess = (currentGateway) => ({
  type: actionTypes.SAVE_CURRENT_GATEWAY_SUCCESS,
  payload: { currentGateway },
});

export const fetchRolesSuccess = (allroles) => ({
  type: actionTypes.GET_ROLES_SUCCESS,
  payload: { allroles },
});
export const fetchGatewayChargeBearerSuccess = (gatewaychargebearer) => ({
  type: actionTypes.GET_GATEWAY_CHARGE_BEARER_SUCCESS,
  payload: { gatewaychargebearer },
});
export const fetchUsersSuccess = (allusers) => ({
  type: actionTypes.GET_USER_SUCCESS,
  payload: { allusers },
});
export const fetchStatesSuccess = (states) => ({
  type: actionTypes.GET_STATES_SUCCESS,
  payload: { states },
});
export const fetchPermissionsSuccess = (allpermissions) => ({
  type: actionTypes.GET_PERMISSIONS_SUCCESS,
  payload: { allpermissions },
});

export const fetchCurrencySuccess = (currency) => ({
  type: actionTypes.GET_CURRENCY_SUCCESS,
  payload: { currency },
});

export const fetchPlatformChargeTypeSuccess = (chargetypes) => ({
  type: actionTypes.GET_PLATFORM_CHARGE_TYPES_SUCCESS,
  payload: { chargetypes },
});
export const fetchGatewayChargeTypeSuccess = (gatewaychargetypes) => ({
  type: actionTypes.GET_GATEWAY_CHARGE_TYPES_SUCCESS,
  payload: { gatewaychargetypes },
});
export const fetchCurrencyPairSuccess = (currencypair) => ({
  type: actionTypes.GET_CURRENCY_PAIR_SUCCESS,
  payload: { currencypair },
});
export const fetchBankSuccess = (bank) => ({
  type: actionTypes.GET_BANK_SUCCESS,
  payload: { bank },
});
export const fetchGatewayAccountsByClientSuccess = (
  gatewayaccountsbyclient
) => ({
  type: actionTypes.GET_GATEWAY_ACCOUNTS_BY_CLIENT,
  payload: { gatewayaccountsbyclient },
});
export const fetchGatewaysByClientSuccess = (gatewaysbyclient) => ({
  type: actionTypes.GET_GATEWAYS_BY_CLIENT,
  payload: { gatewaysbyclient },
});

export const fetchAllResponseCodeSuccess = (responseCodes) => ({
  type: actionTypes.GET_RESPONSE_CODE_SUCCESS,
  payload: { responseCodes },
});

export const fetchAllConfigurationsSuccess = (configurations) => ({
  type: actionTypes.GET_ALL_CONFIGURATIONS_SUCCESS,
  payload: { configurations },
});
export const fetchServicesSuccess = (services) => ({
  type: actionTypes.GET_SERVICES_SUCCESS,
  payload: { services },
});

export const fetchClientsSuccess = (allclients) => ({
  type: actionTypes.GET_CLIENTS_SUCCESS,
  payload: { allclients },
});
export const fetchKycLevelsSuccess = (kyclevels) => ({
  type: actionTypes.GET_KYC_LEVELS_SUCCESS,
  payload: { kyclevels },
});
export const fetchGatewayCredentialKeysSuccess = (gatewayCredentialkeys) => ({
  type: actionTypes.GET_GATEWAY_CREDENTIAL_KEY_SUCCESS,
  payload: { gatewayCredentialkeys },
});

export const fetchGatewaysSuccess = (gateways) => ({
  type: actionTypes.GET_GATEWAYS_SUCCESS,
  payload: { gateways },
});

export const fetchClientConfigurationsSuccess = (clientConfigurations) => ({
  type: actionTypes.GET_CLIENT_CONFIGURATIONS,
  payload: { clientConfigurations },
});

export const fetchMicroServicesSuccess = (microservices) => ({
  type: actionTypes.GET_MICROSERVICES_SUCCESS,
  payload: { microservices },
});
export const fetchPaymentServiceGroupsSuccess = (servicegroups) => ({
  type: actionTypes.GET_PAYMENT_SERVICE_GROUPS_SUCCESS,
  payload: { servicegroups },
});
export const fetchCountriesSuccess = (countries) => ({
  type: actionTypes.GET_COUNTRIES_SUCCESS,
  payload: { countries },
});

// export const fetchCountrySuccess = (country) => ({
//   type: actionTypes.GET_COUNTRY_SUCCESS,
//   payload: { country },
// });
export const fetchClientServicesSuccess = (clientservices) => ({
  type: actionTypes.GET_CLIENTSERVICES_SUCCESS,
  payload: { clientservices },
});

export const fetchSettlementTransactionsPending = () => ({
  type: actionTypes.GET_SETTLEMENT_TRANSACTIONS_PENDING,
});

export const fetchSettlementTransactionsSuccess = (settlementTransactions) => ({
  type: actionTypes.GET_SETTLEMENT_TRANSACTIONS_SUCCESS,
  payload: settlementTransactions,
});

export const postSettlementTransactionsPending = () => ({
  type: actionTypes.POST_SETTLEMENT_TRANSACTIONS_PENDING,
});
export const postSettlementTransactionsSuccess = (payload) => ({
  type: actionTypes.POST_SETTLEMENT_TRANSACTIONS_SUCCESS,
  payload: payload,
});

export const postSettlementTransactionsError = (payload) => ({
  type: actionTypes.POST_SETTLEMENT_TRANSACTIONS_ERROR,
  payload: payload,
});

// Redux Actions Api Calls

export function fetchAllGatewayChargeBearer(
  ServiceBase,
  Constants,
  history,
  searchData
) {
  return (dispatch) => {
    return (
      ServiceBase.getReduxItems(
        Constants.GATEWAY_CHARGE_BEARER,
        Constants.GET_ALL,
        1000,
        1
      )
        .then((res) => {
          const { data } = res;
          if (data) {
            if (data.responseData) {
              dispatch(
                fetchGatewayChargeBearerSuccess(data.responseData.items)
              );
            }
          }
          //debugger
          return data;
        })
        // .catch(error => dispatch(fetchProductsFailure(error)));
        .catch((error) => {
          return error;
        })
    );
  };
}

export function fetchAllRoles(ServiceBase, Constants, history, searchData) {
  return (dispatch) => {
    return (
      ServiceBase.getReduxItemsFilter(
        Constants.ROLES,
        Constants.GET_ALL,
        1000,
        1,
        searchData
      )
        .then((res) => {
          const { data } = res;
          if (data) {
            if (data.responseData) {
              dispatch(fetchRolesSuccess(data.responseData.items));
            }
          }
          //debugger
          return data;
        })
        // .catch(error => dispatch(fetchProductsFailure(error)));
        .catch((error) => {
          return error;
        })
    );
  };
}

export function fetchAllPermissions(
  ServiceBase,
  Constants,
  history,
  isAdmin,
  context
) {
  return (dispatch) => {
    return (
      ServiceBase.getReduxItemsById(
        Constants.PERMISSIONS,
        Constants.GET_ALL,
        1000,
        1,
        isAdmin
      )
        .then((res) => {
          const { data } = res;
          if (data) {
            if (data.responseData) {
              dispatch(fetchPermissionsSuccess(data.responseData));
              if (context) {
                context.setState({ showPermissionCheckboxes: false });
              }
            }
          }
          //debugger
          return data;
        })
        // .catch(error => dispatch(fetchProductsFailure(error)));
        .catch((error) => {
          return error;
        })
    );
  };
}

export function fetchAllCurrency(ServiceBase, Constants, history) {
  return (dispatch) => {
    return (
      ServiceBase.getReduxItems(Constants.CURRENCY, Constants.GET_ALL, 1000, 1)
        .then((res) => {
          const { data } = res;
          if (data) {
            if (data.responseData) {
              dispatch(fetchCurrencySuccess(data.responseData.items));
            }
          }
          //debugger
          return data;
        })
        // .catch(error => dispatch(fetchProductsFailure(error)));
        .catch((error) => {
          return error;
        })
    );
  };
}

export function fetchAllPlatformChargeType(ServiceBase, Constants, history) {
  return (dispatch) => {
    return (
      ServiceBase.getInfo(Constants.PLATFORM_CHARGE, Constants.GET_CHARGE_TYPE)
        .then((res) => {
          const { data } = res;
          if (data) {
            if (data.responseData) {
              dispatch(fetchPlatformChargeTypeSuccess(data.responseData));
            }
          }
          //debugger
          return data;
        })
        // .catch(error => dispatch(fetchProductsFailure(error)));
        .catch((error) => {
          return error;
        })
    );
  };
}

export function fetchAllGatewayChargeType(ServiceBase, Constants, history) {
  return (dispatch) => {
    return (
      ServiceBase.getInfo(Constants.GATEWAY_CHARGE, Constants.GET_CHARGE_TYPE)
        .then((res) => {
          const { data } = res;
          if (data) {
            if (data.responseData) {
              dispatch(fetchGatewayChargeTypeSuccess(data.responseData));
            }
          }
          //debugger
          return data;
        })
        // .catch(error => dispatch(fetchProductsFailure(error)));
        .catch((error) => {
          return error;
        })
    );
  };
}

export function fetchAllUsers(ServiceBase, Constants, history) {
  return (dispatch) => {
    return (
      ServiceBase.getReduxItems(Constants.GET_USER, Constants.GET_ALL, 1000, 1)
        .then((res) => {
          const { data } = res;
          if (data) {
            if (data.responseData) {
              dispatch(fetchUsersSuccess(data.responseData.items));
            }
          }
          //debugger
          return data;
        })
        // .catch(error => dispatch(fetchProductsFailure(error)));
        .catch((error) => {
          return error;
        })
    );
  };
}
export function fetchAllStates(ServiceBase, Constants, history, searchData) {
  return (dispatch) => {
    return (
      ServiceBase.getReduxItemsFilter(
        Constants.STATE,
        Constants.GET_ALL,
        1000,
        1,
        searchData
      )
        .then((res) => {
          const { data } = res;
          if (data) {
            if (data.responseData) {
              dispatch(fetchStatesSuccess(data.responseData.items));
            }
          }
          //debugger
          return data;
        })
        // .catch(error => dispatch(fetchProductsFailure(error)));
        .catch((error) => {
          return error;
        })
    );
  };
}

export function fetchAllCurrencyPair(ServiceBase, Constants, history) {
  return (dispatch) => {
    return (
      ServiceBase.getReduxItems(
        Constants.CURRENCY_PAIR,
        Constants.GET_ALL,
        1000,
        1
      )
        .then((res) => {
          const { data } = res;
          if (data) {
            if (data.responseData) {
              dispatch(fetchCurrencyPairSuccess(data.responseData.items));
            }
          }
          //debugger
          return data;
        })
        // .catch(error => dispatch(fetchProductsFailure(error)));
        .catch((error) => {
          return error;
        })
    );
  };
}

export function fetchAllBanks(ServiceBase, Constants, history) {
  return (dispatch) => {
    return (
      ServiceBase.getReduxItems(Constants.BANK, Constants.GET_ALL, 1000, 1)
        .then((res) => {
          const { data } = res;
          if (data) {
            if (data.responseData) {
              dispatch(fetchBankSuccess(data.responseData.items));
            }
          }
          //debugger
          return data;
        })
        // .catch(error => dispatch(fetchProductsFailure(error)));
        .catch((error) => {
          return error;
        })
    );
  };
}
export function fetchAllResponseCode(ServiceBase, Constants, history) {
  return (dispatch) => {
    return (
      ServiceBase.getReduxItems(
        Constants.RESPONSE_CODE,
        Constants.GET_ALL,
        1000,
        1
      )
        .then((res) => {
          const { data } = res;
          if (data) {
            if (data.responseData) {
              dispatch(fetchAllResponseCodeSuccess(data.responseData.items));
            }
          }
          //debugger
          return data;
        })
        // .catch(error => dispatch(fetchProductsFailure(error)));
        .catch((error) => {
          return error;
        })
    );
  };
}

export function fetchAllServices(ServiceBase, Constants, history, callback) {
  return (dispatch) => {
    return (
      ServiceBase.getReduxItems(Constants.SERVICES, Constants.GET_ALL, 1000, 1)
        .then((res) => {
          const { data } = res;
          if (data) {
            if (data.responseData) {
              dispatch(fetchServicesSuccess(data.responseData.items));
              if (callback) {
                callback();
              }
            }
          }
          //debugger
          return data;
        })
        // .catch(error => dispatch(fetchProductsFailure(error)));
        .catch((error) => {
          return error;
        })
    );
  };
}

export function fetchAllClients(ServiceBase, Constants, history) {
  return (dispatch) => {
    return (
      ServiceBase.getReduxItems(Constants.CLIENTS, Constants.GET_ALL, 1000, 1)
        .then((res) => {
          const { data } = res;
          if (data) {
            if (data.responseData) {
              dispatch(fetchClientsSuccess(data.responseData.items));
            }
          }
          //debugger
          return data;
        })
        // .catch(error => dispatch(fetchProductsFailure(error)));
        .catch((error) => {
          return error;
        })
    );
  };
}

export function fetchAllKycLevels(ServiceBase, Constants, history) {
  return (dispatch) => {
    return (
      ServiceBase.getReduxItems(
        Constants.KYC_SERVICE_LEVEL,
        Constants.GET_KYC_LEVELS,
        1000,
        1
      )
        .then((res) => {
          const { data } = res;
          if (data) {
            if (data.responseData) {
              dispatch(fetchKycLevelsSuccess(data.responseData));
            }
          }
          //debugger
          return data;
        })
        // .catch(error => dispatch(fetchProductsFailure(error)));
        .catch((error) => {
          return error;
        })
    );
  };
}

// with serviceId and gatewayId
export function fetchGatewayCredentialKeysServiceIdGatewayId(
  ServiceBase,
  Constants,
  history,
  idOne,
  idTwo,
  obj,
  isEdit
) {
  return (dispatch) => {
    return (
      ServiceBase.getReduxItemsByTwoIds(
        Constants.GATEWAY_CREDENTIAL_KEY,
        Constants.GET,
        1000,
        1,
        idOne,
        idTwo
      )

        .then((res) => {
          const { data } = res;
          if (data) {
            if (data.responseData) {
              if (isEdit === true) {
                obj.setState((prevState) => ({
                  clientGatewayAccountEditData: {
                    ...prevState.clientGatewayAccountEditData,
                    GatewayAccountCredentials: appHelpers.formatCredentialKeys(
                      data.responseData.items
                    ),
                  },
                }));
              } else {
                obj.setState({
                  GatewayAccountCredentials: appHelpers.formatCredentialKeys(
                    data.responseData.items
                  ),
                });
              }
              dispatch(
                fetchGatewayCredentialKeysSuccess(data.responseData.items)
              );
              obj.setState({ loadingCredentialKeys: false });
            }
          }
          //debugger
          return data;
        })
        // .catch(error => dispatch(fetchProductsFailure(error)));
        .catch((error) => {
          return error;
        })
    );
  };
}
//

export function fetchGatewayCredentialKeys(
  ServiceBase,
  Constants,
  history,
  id,
  obj,
  isEdit
) {
  return (dispatch) => {
    return (
      ServiceBase.getReduxItemsById(
        Constants.GATEWAY_CREDENTIAL_KEY,
        Constants.GET_BY_GATEWAY_ID,
        1000,
        1,
        id
      )

        .then((res) => {
          const { data } = res;
          if (data) {
            if (data.responseData) {
              if (isEdit === true) {
                obj.setState((prevState) => ({
                  clientGatewayAccountEditData: {
                    ...prevState.clientGatewayAccountEditData,
                    GatewayAccountCredentials: appHelpers.formatCredentialKeys(
                      data.responseData.items
                    ),
                  },
                }));
              } else {
                obj.setState({
                  GatewayAccountCredentials: appHelpers.formatCredentialKeys(
                    data.responseData.items
                  ),
                });
              }
              dispatch(
                fetchGatewayCredentialKeysSuccess(data.responseData.items)
              );
              obj.setState({ loadingCredentialKeys: false });
            }
          }
          //debugger
          return data;
        })
        // .catch(error => dispatch(fetchProductsFailure(error)));
        .catch((error) => {
          return error;
        })
    );
  };
}

export function fetchGateways(ServiceBase, Constants, history) {
  return (dispatch) => {
    return (
      ServiceBase.getReduxItems(Constants.GATEWAYS, Constants.GET_ALL, 1000, 1)
        .then((res) => {
          const { data } = res;
          if (data) {
            if (data.responseData) {
              dispatch(fetchGatewaysSuccess(data.responseData.items));
            }
          }
          //debugger
          return data;
        })
        // .catch(error => dispatch(fetchProductsFailure(error)));
        .catch((error) => {
          return error;
        })
    );
  };
}

export function fetchClientConfigurations(
  ServiceBase,
  Constants,
  history,
  clientId
) {
  return (dispatch) => {
    return (
      ServiceBase.getReduxItemsById(
        Constants.CONFIGURATION,
        Constants.GET_ALL_CONFIGURATIONS_BY_CLIENT_ID,
        1000,
        1,
        clientId
      )
        .then((res) => {
          const { data } = res;
          if (data) {
            if (data.responseData) {
              dispatch(
                fetchClientConfigurationsSuccess(data.responseData.items)
              );
            }
          }
          //debugger
          return data;
        })
        // .catch(error => dispatch(fetchProductsFailure(error)));
        .catch((error) => {
          return error;
        })
    );
  };
}

export function fetchClientServices(ServiceBase, Constants, history, clientId) {
  return (dispatch) => {
    return (
      ServiceBase.getReduxItemsById(
        Constants.CLIENT_SERVICES,
        Constants.GET_ALL_BY_CLIENT_ID,
        1000,
        1,
        clientId
      )
        .then((res) => {
          const { data } = res;
          if (data) {
            if (data.responseData) {
              dispatch(fetchClientServicesSuccess(data.responseData.items));
            }
          }
          //debugger
          return data;
        })
        // .catch(error => dispatch(fetchProductsFailure(error)));
        .catch((error) => {
          return error;
        })
    );
  };
}
export function fetchGatewayAccountsByClient(
  ServiceBase,
  Constants,
  history,
  clientId
) {
  return (dispatch) => {
    return (
      ServiceBase.getReduxItemsById(
        Constants.GATEWAY_ACCOUNT,
        Constants.GET_ALL_BY_CLIENT_ID,
        1000,
        1,
        clientId
      )
        .then((res) => {
          const { data } = res;
          if (data) {
            if (data.responseData) {
              const filteredGatewayAccounts = data.responseData.items.filter(
                ({ accountName, gatewayAccountNumber }) =>
                  accountName !== null && gatewayAccountNumber !== null
              );
              if (filteredGatewayAccounts.length) {
                dispatch(
                  fetchGatewayAccountsByClientSuccess(filteredGatewayAccounts)
                );
              } else {
                dispatch(fetchGatewayAccountsByClientSuccess([]));
              }
            }
          }
          //debugger
          return data;
        })
        // .catch(error => dispatch(fetchProductsFailure(error)));
        .catch((error) => {
          return error;
        })
    );
  };
}

export function fetchGatewaysByClient(
  ServiceBase,
  Constants,
  history,
  clientId
) {
  return (dispatch) => {
    return (
      ServiceBase.getReduxItemsById(
        Constants.GATEWAYS,
        Constants.GET_ALL_BY_CLIENT_ID,
        1000,
        1,
        clientId
      )
        .then((res) => {
          const { data } = res;
          if (data) {
            if (data.responseData) {
              dispatch(fetchGatewaysByClientSuccess(data.responseData.items));
            }
          }
          //debugger
          return data;
        })
        // .catch(error => dispatch(fetchProductsFailure(error)));
        .catch((error) => {
          return error;
        })
    );
  };
}

export function fetchAllConfigurations(ServiceBase, Constants, history) {
  return (dispatch) => {
    return (
      ServiceBase.getReduxItems(
        Constants.CONFIGURATION,
        Constants.GET_ALL,
        1000,
        1
      )
        .then((res) => {
          const { data } = res;
          if (data) {
            if (data.responseData) {
              dispatch(fetchAllConfigurationsSuccess(data.responseData.items));
            }
          }
          //debugger
          return data;
        })
        // .catch(error => dispatch(fetchProductsFailure(error)));
        .catch((error) => {
          return error;
        })
    );
  };
}

export function fetchCountries(ServiceBase, Constants, history) {
  return (dispatch) => {
    return (
      ServiceBase.getReduxItems(Constants.COUNTRY, Constants.GET_ALL, 1000, 1)
        .then((res) => {
          const { data } = res;
          if (data) {
            if (data.responseData) {
              dispatch(fetchCountriesSuccess(data.responseData.items));
            }
          }
          //debugger
          return data;
        })
        // .catch(error => dispatch(fetchProductsFailure(error)));
        .catch((error) => {
          return error;
        })
    );
  };
}

export function fetchMicroServices(ServiceBase, Constants, history) {
  return (dispatch) => {
    return (
      ServiceBase.getReduxItems(
        Constants.MICRO_SERVICES,
        Constants.GET_ALL,
        1000,
        1
      )
        .then((res) => {
          const { data } = res;
          if (data) {
            if (data.responseData) {
              dispatch(fetchMicroServicesSuccess(data.responseData.items));
            }
          }
          //debugger
          return data;
        })
        // .catch(error => dispatch(fetchProductsFailure(error)));
        .catch((error) => {
          return error;
        })
    );
  };
}

export function fetchPaymentServiceGroups(ServiceBase, Constants, history) {
  return (dispatch) => {
    return (
      ServiceBase.getReduxItems(
        Constants.SERVICE_GROUPS,
        Constants.GET_ALL,
        1000,
        1
      )
        .then((res) => {
          const { data } = res;
          if (data) {
            if (data.responseData) {
              dispatch(
                fetchPaymentServiceGroupsSuccess(data.responseData.items)
              );
            }
          }
          //debugger
          return data;
        })
        // .catch(error => dispatch(fetchProductsFailure(error)));
        .catch((error) => {
          return error;
        })
    );
  };
}

export function fetchSettlementTransactions({
  startDate,
  endDate,
  pageSize,
  pageNo,
  keyword,
}) {
  return (dispatch) => {
    dispatch(fetchSettlementTransactionsPending());

    return fetch(
      `https://vgnpc-settlement-dev.test.vggdev.com/api/v1/settlement/gettransactions?startDate=${startDate}&endDate=${endDate}&pageSize=${pageSize}&pageNumber=${pageNo}${
        keyword ? "&keyword=" + keyword : ""
      }`
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.responseData) {
          dispatch(fetchSettlementTransactionsSuccess(data.responseData));
        }
      })
      .catch((error) => {
        return error;
      });
  };
}

export function postSettlementTransactions(data) {
  return (dispatch) => {
    dispatch(postSettlementTransactionsPending());

    return fetch(
      `https://vgnpc-settlement-dev.test.vggdev.com/api/v1/settlement/settletransactions`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(data),
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.responseData) {
          dispatch(postSettlementTransactionsSuccess(data));
        } else {
          dispatch(postSettlementTransactionsError(data.message));
        }
      })
      .catch((error) => {
        dispatch(postSettlementTransactionsError(error.response));
        return error;
      });
  };
}

//  other dispatches

export function saveExpiration(expiresIn) {
  return (dispatch) => {
    return dispatch(saveexpirationSuccess(expiresIn));
  };
}
export function saveUserData(data) {
  return (dispatch) => {
    return dispatch(saveuserDataSuccess(data));
  };
}

export function handlesaveCurrentClient(currentBusiness) {
  return (dispatch) => {
    return dispatch(savecurrentClientSuccess(currentBusiness));
  };
}
export function handlesaveTestData(testData) {
  return (dispatch) => {
    return dispatch(savetestDataSuccess(testData));
  };
}

export function handlesaveCurrentGateway(currentGateway) {
  return (dispatch) => {
    return dispatch(savecurrentGatewaySuccess(currentGateway));
  };
}

export function handlesaveClientNavigationKey(clientNavigationKey) {
  return (dispatch) => {
    return dispatch(saveClientNavigationKeySuccess(clientNavigationKey));
  };
}
export default actionTypes;
